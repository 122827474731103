import { Transition, Dialog } from "@headlessui/react"
import { Fragment } from "react";
import { bannerType, bannerTypeEnum } from "./interface";
import { Button } from "@mui/material";
import { X } from "react-feather";
import { apiCall, METHOD } from "../../../../service/baseApiCall";
import { DELETE_BANNER } from "../../../../service/apiEndPoints";
import { toast } from "react-toastify";

const DeleteBannerModal = (props: bannerType) => {

    const submit = () => {
        apiCall(
            DELETE_BANNER.replace(":sku", props.sku),
            {},
            () => {
                props.setOpen(false)
                toast.success("배너를 삭제하였습니다.")
                setTimeout(() => {
                    window.location.reload()
                }, 1000)
            },
            (err: any) => {

                if( err === "unauthorized") {
                    toast.error("로그인 정보가 만료되었습니다. 다시 로그인 해주세요.")
                } else {
                    toast.error("배너를 삭제하는데 실패하였습니다.", err)
                }

                props.setOpen(false)
                return
            },
            METHOD.DELETE,
            {}
        )
    }   

    return (
        <Transition appear show={props.isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => props.setOpen(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-[20%] min-w-[280px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-xl font-medium leading-6 text-gray-900"
                            >
                                <div className="flex justify-between">
                                    <h1 className="text-center flex items-center justify-center">
                                        배너 삭제 
                                    </h1>
                                    <div>
                                        <X 
                                            className="text-white sm:h-8 sm:w-8 bg-[#d23434] rounded shadow-md hover:cursor-pointer" 
                                            onClick={() => props.setOpen(false)}
                                        />
                                    </div>
                                </div>
                                
                                </Dialog.Title>
                                <div className="mt-4 flex flex-col gap-4">
                                    <div>
                                    </div>
                                    <Button 
                                        variant="contained" 
                                        color="success" 
                                        onClick={() => {
                                            submit()
                                        }}
                                    >
                                        배너를 삭제하시겠습니까?
                                    </Button>
                                </div>
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
    )
} 

export const BannerManage = (props: bannerType) => {
    return (
        <div>
            {
                props.type === bannerTypeEnum.DELETE ? (
                    <DeleteBannerModal 
                        isOpen={props.isOpen} 
                        setOpen={props.setOpen}
                        sku={props.sku}
                        type={props.type}
                    />
                ) : null
            }
        </div>
    )
}   