import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";
import "./banner.css"
import { apiCall, METHOD } from "../../../../service/baseApiCall";
import { GET_BANNER } from "../../../../service/apiEndPoints";
import { bannerBody } from "../../../Admin/components/Banner/interface";
const BANNER_AUTOPLAY_INTERVAL = 5 * 1000 // ms

export const Banner = () => {
    const [images, setImages] = useState<bannerBody[]>([])
    const [noData, setNoData] = useState<boolean>(true)

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        apiCall(
            GET_BANNER,
            {},
            (res: any) => {
                setImages(res.data)
                setNoData(false)
            },
            (err: any) => {
                setNoData(true)
                setImages([])
            },
            METHOD.GET,
            {}
        )
    }
    
    const settings = {
        dots: false,
        infinite:  noData ? false : images.length > 1, // 배너가 1개 이상일 때만 무한 스크롤 활성화
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: noData ? false : images.length > 1, // 배너가 1개 이상일 때만 자동 재생
        autoplaySpeed: BANNER_AUTOPLAY_INTERVAL,
        arrows: noData ? false : images.length > 1, // 배너가 1개 이상일 때만 화살표 표시
        draggable: noData ? false : images.length > 1, // 배너가 1개 이상일 때만 드래그 가능
    };

    return (
        <div className={`${noData ? "hidden" : "banner-container"} `}> 
            {images.length === 1 ? (
                // 배너가 하나일 때는 슬라이더 사용하지 않음
                <a href={images[0].banner_link} target="_blank" rel="noreferrer noopener">
                    <img 
                        id={images[0].id}
                        src={images[0].banner_url} 
                        alt="" 
                        className="banner-img" 
                    />
                </a>
            ) : (
                // 배너가 여러 개일 때는 슬라이더 사용
                <Slider {...settings}>
                    {images.map((item) => (
                        <a key={item.id} href={item.banner_link} target="_blank" rel="noreferrer noopener">
                            <img 
                                key={item.id}
                                id={item.id}
                                src={item.banner_url} 
                                alt="" 
                                className="banner-img"
                            />
                        </a>
                    ))}
                </Slider>
            )}
        </div>
    )
}