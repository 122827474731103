export const SAM_TYPES = {
    ALL : 'all',
    TIZEN : 'tizen',
    WEAR : 'wear'
    
}

export const MENU_TYPE = {
    SAM_WATCH: "samWatch",
    YAM_WATCH: "yamWatch"
}

export const BROWSER_TYPES = {
    CHROME: "chrome",
    SAFARI: "safari",
    FIREFOX: "firefox"
}

export const SAM_COLOR = {
    SAM_WATCH:  ["#1e40af", "#002b71"],
    YAM_WATCH:  ["#fcc92c", "#e5006e"],
    SAM_THEME:  ["#2dd4bf", "#0284c7"],
    SAM_STUDIO: ["#e11d48", "#f97316"],
}

// 할인 타입: 워치 또는 테마
export const DISCOUNT_TYPE = {
    WATCH: "watch",
    THEME: "theme"
}