import { Dialog, Transition } from "@headlessui/react"
import { Fragment } from "react";
import { apiCall, METHOD  } from "../../../../service/baseApiCall";
import { DISABLE_DISCOUNT } from "../../../../service/apiEndPoints";
import { Button } from "@mui/material";
import { DISCOUNT_TYPE } from "../../../App/utils/constants";
import { X } from "react-feather";
import { toast } from "react-toastify";

interface DisableModalProps {
    isOpen: boolean
    setOpen: (open: boolean) => void
    sku: string
    selectedItemDiscount: boolean
}

interface DisableSubmitProps {
    sku: string
    type: string
    setOpen: (open: boolean) => void
}

const submit = (props: DisableSubmitProps) => {
    const URL = DISABLE_DISCOUNT.replace(":type", props.type).replace(":sku", props.sku)
    apiCall(
        URL,
        {},
        (res: any) => {
            props.setOpen(false)
            toast.success("할인을 종료하였습니다.")
            setTimeout(() => {
                window.location.reload()
            }, 1000)
            return
        },
        (err: any) => {
            props.setOpen(false)
            toast.error("할인을 종료하는데 실패하였습니다.", err)
            return
        },
        METHOD.DELETE,
        {
            headers: { "Content-Type": "multipart/form-data" }
        }
    )
}

const DisableModal = (props: DisableModalProps) => {
    return (
            <Transition appear show={props.isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => props.setOpen(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-[20%] min-w-[280px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-xl font-medium leading-6 text-gray-900"
                            >
                                <div className="flex justify-between">
                                    <h1 className="text-center flex items-center justify-center">
                                        세일 종료
                                    </h1>
                                    <div>
                                        <X 
                                            className="text-white sm:h-8 sm:w-8 bg-[#d23434] rounded shadow-md hover:cursor-pointer" 
                                            onClick={() => props.setOpen(false)}
                                        />
                                    </div>
                                </div>
                                
                                </Dialog.Title>
                                <div className="mt-4 flex flex-col gap-4">
                                    <div>
                                        {props.selectedItemDiscount ? "활성화 된 할인을 종료하시겠습니까?" : "할인이 활성화되어 있지 않습니다."}
                                    </div>
                                    <Button 
                                        variant="contained" 
                                        color="success" 
                                        onClick={() => {
                                            if(props.selectedItemDiscount) {
                                                submit({ 
                                                    sku: props.sku, 
                                                    type: DISCOUNT_TYPE.WATCH, 
                                                    setOpen: props.setOpen 
                                                })
                                                return
                                            }
                                            props.setOpen(false)
                                        }}
                                    >
                                        {props.selectedItemDiscount ? "종료하기" : "닫기"}
                                    </Button>
                                </div>
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
    )
}

export default DisableModal;