import { Pagination, ThemeName } from "../../utils/enums"
import { Navigation } from "../NavigationBar"
import { useState, useEffect, useRef } from "react"
import { SAM_COLOR, SAM_TYPES } from "../../utils/constants"
import { GET_SAM_SWITCH } from "../../../../service/apiEndPoints";
import { METHOD, apiCall } from "../../../../service/baseApiCall";
import { toast } from "react-toastify";
import { FYamWatchBody } from "./body";
import CouponPopup from "../common/CouponPopup";
import { useLoadState } from "../../../common/globalState";
import { CircularIndicator } from "../common/Indicator";

export const FYamWatch = () => {

    const [data, setData] = useState<any>([])
    const [pagination, setPagination] = useState<Pagination>({
        column: "",
        limit: 18,
        offset: 0,
        order: "created_at",
        page: 1,
        query: "",
        sort: "ASC",
        total: 1,
        total_page: 1,
    })
    const [page, setPage] = useState(1)
    const [loading, setLoader] = useState(true)
    const [isOpen, setIsOpen] = useState(false)
    const [floatingClick, setFloatingClick] = useState<boolean>(false)
    const [selectedWatchFrame, setselectedWatchFrame] = useState<string>("")
    const [selectedType, setSelectedType] = useState(SAM_TYPES.ALL);
    const [product, setProduct] = useState();
    const {showProgressBar, setProgressBar} = useLoadState()

    useEffect(() => {
        setProgressBar(true)
        setLoader(true);
        setPage(1);
        const URL = GET_SAM_SWITCH.replace(":type", "yam")
        .replace(":limit", "40")
        .replace(":page", "1")
        .replace(":os", "")
        .replace(":name_sku", "");
        apiCall(
        URL,
        {},
        (res: any) => {
            if (res?.success) {
                setData(res?.data);
                setPagination(res?.pagination);
                setProgressBar(false)
                setLoader(false);
            } else {
            if (res?.data?.message === "record not found") {
                setData([]);
                setProgressBar(false)
                setLoader(false);
            }
            }
        },
        (err: any) => {
            console.log(err);
            setProgressBar(false)
            setLoader(false);
            toast.error("something went wrong!");
        },
        METHOD.GET,
        {}
        );
    }, [selectedType]);

    
    const loadMore = () => {
        setProgressBar(true)
        const URL = GET_SAM_SWITCH.replace(":type", "watch")
        .replace(":limit", "40")
        .replace(":page", (page + 1).toString())
        .replace(":os", "")
        .replace(":name_sku", "");
        apiCall(
        URL,
        {},
        (res: any) => {
            if (res?.success) {
            const newData = [...data, ...res?.data];
            setData(newData);
            setPagination(res?.pagination);
            setProgressBar(false)
            }
        },
        (err: any) => {
            console.log(err);
            setProgressBar(false)
        },
        METHOD.GET,
        {}
        );
    };

    return (
        <div className={`bg-black w-screen`}>
            <Navigation name={ThemeName.YAM_WATCH} />
            <div>
                <FYamWatchBody 
                    loading={loading}
                    data={data}
                    page={page}
                    pagination={pagination}
                    selectedType={selectedType}
                    selectedWatchFrame={selectedWatchFrame}
                    floatingClick={floatingClick}
                    showProgressBar={showProgressBar}
                    loadMore={loadMore}
                    setPage={setPage}
                    setFloatingClick={setFloatingClick}
                    setProduct={setProduct}
                    setIsOpen={setIsOpen}
                    setSelectedWatchFrame={setselectedWatchFrame}
                />
            </div>
            <CouponPopup 
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                product={product}
                selected={selectedType}
                selectedWatchFrame={selectedWatchFrame}
            /> 
            <CircularIndicator 
                color={SAM_COLOR.YAM_WATCH}
                show={showProgressBar}
            />
        </div>
    )
}