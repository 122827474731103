import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { removeDataFromLocalStorage } from '../../../App/utils/util';

export default function ReLoginModal(props: { isOpen: boolean; setIsOpen: (arg0: boolean) => void; }) {
  const navigate = useNavigate();

  function closeModal() {
    props.setIsOpen(false)
  }

  function openModal() {
    props.setIsOpen(true)
  }

  function toLogin() {
    navigate('/login');
  }

  useEffect(() => {
    if(props.isOpen) {
      removeDataFromLocalStorage("login-auth-token");
      removeDataFromLocalStorage("isLoggedIn")
      removeDataFromLocalStorage("email")
      removeDataFromLocalStorage("refresh-token")
    }
  }, [props.isOpen])

  return (
    <Transition appear show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={toLogin}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-xl font-medium leading-6 text-gray-900"
                >
                  로그인 유효시간 만료
                </Dialog.Title>
                <div className="mt-4">
                  <p className="text-sm text-gray-500">
                    로그인 유효시간이 만료되었습니다. 다시 로그인 해주세요.
                  </p>
                </div>

                <div className="mt-4 flex flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-sky-400 px-4 py-2 text-sm font-medium text-white hover:bg-sky-600"
                    onClick={toLogin}
                  >
                    로그인
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
