export interface modalProps {
    isOpen: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export interface bannerBody {
    id: string 
    sku: string
    banner_link: string
    banner_url: string
    created_at: string
    sequence: number
}



export interface bannerManageProps {
    isOpen: boolean
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    sku: string
}

export interface bannerType extends bannerManageProps {
    type: bannerTypeEnum
}

export enum bannerTypeEnum {
    UPDATE = "update",
    DELETE = "delete",
}