import { Key, useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { GET_SAM_SWITCH } from "../../../../service/apiEndPoints";
import { apiCall, METHOD } from "../../../../service/baseApiCall";
import { MENU_TYPE, SAM_COLOR, SAM_TYPES } from "../../utils/constants";
import { Pagination, ThemeName } from "../../utils/enums";
import CarouselList from "../common/CarouselList";
import CouponPopup from "../common/CouponPopup";
import { LoadMore } from "../common/LoadMore";
import { NoData } from "../common/NoData";
import { Navigation } from "../NavigationBar";
import { FloatingMenu } from "../common/FloatingMenu";
// import { GoogleAds } from "../common/Ads";
import { CircularIndicator } from "../common/Indicator";
import { useLoadState } from "../../../common/globalState";
import { Banner } from "../Banner";
// import SearchBar from "../SearchBar";
// import SearchModal from "../SearchBar/searchModal";


export const FSamWatch = () => {
  const targetRef = useRef<HTMLDivElement>(null);
  const [data, setData] = useState([] as any);
  const [pagination, setPagination] = useState<Pagination>({
    column: "",
    limit: 18,
    offset: 0,
    order: "created_at",
    page: 1,
    query: "",
    sort: "ASC",
    total: 1,
    total_page: 1,
  });
  const [selectedType, setSelectedType] = useState(SAM_TYPES.ALL);
  const [loading, setLoader] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [floatingClick, setFloatingClick] = useState<boolean>(false)
  const [selectedWatchFrame, setSelectedWatchFrame] = useState<string>("")

  // TODO - search bar
  // const [isSbOpen, setIsSbOpen ] = useState(false) // 서치바와 쿠폰 모달이 혼동되지 않게 하기 위해 별도로 state 관리

  const [product, setProduct] = useState();
  const [page, setPage] = useState(1);
  const {showProgressBar , setProgressBar} = useLoadState() 

  useEffect(() => {
    setProgressBar(true)
    setLoader(true);
    setPage(1);
    const URL = GET_SAM_SWITCH.replace(":type", "watch")
      .replace(":limit", "40")
      .replace(":page", "1")
      .replace(":os", selectedType === "all" ? "" : selectedType)
      .replace(":name_sku", "");
    apiCall(
      URL,
      {},
      (res: any) => {
        if (res?.success) {
          setData(res?.data);
          setPagination(res?.pagination);
          setProgressBar(false)
          setLoader(false);
        } else {
          if (res?.data?.message === "record not found") {
            setData([]);
            setProgressBar(false)
            setLoader(false);
          }
        }
      },
      (err: any) => {
        console.log(err);
        setProgressBar(false)
        setLoader(false);
        toast.error("something went wrong!");
      },
      METHOD.GET,
      {}
    );
  }, [selectedType]);

  const loadMore = () => {
    setProgressBar(true)
    const URL = GET_SAM_SWITCH.replace(":type", "watch")
      .replace(":limit", "40")
      .replace(":page", (page + 1).toString())
      .replace(":os", selectedType === "all" ? "" : selectedType)
      .replace(":name_sku", "");
    apiCall(
      URL,
      {},
      (res: any) => {
        if (res?.success) {
          const newData = [...data, ...res?.data];
          setData(newData);
          setPagination(res?.pagination);
          setProgressBar(false)
        }
      },
      (err: any) => {
        console.log(err);
        setProgressBar(true)
      },
      METHOD.GET,
      {}
    );
  };

  return (
    <div>
      <div className={`bg-black w-screen h-4/5`}>
        <Navigation name={ThemeName.SAM_WATCH} />
        <div>
          <ul className="flex flex-wrap mt-2 text-sm font-normal md:font-medium text-center text-gray-500 dark:text-gray-400 justify-center">
            <li className="hover:bg-black pb-1 items-center px-1.5 md:px-4">
              {/* <button
                className={`inline-block py-1 h-fit md:py-3 px-2 md:px-4 text-white rounded-full ${
                  selectedType === SAM_TYPES.ALL
                    ? "bg-white text-black"
                    : " bg-[#6d6d6d] hover:bg-white hover:text-black"
                }`}
                onClick={() => {
                  setSelectedType(SAM_TYPES.ALL);
                }}
              >
                All
              </button> */}
            </li>
            <li className="hover:bg-black pb-1 px-1.5 md:px-4">
              {/* <img
                className={`inline-block py-1 h-10 md:h-11 hover:cursor-pointer`}
                onClick={() => {
                  setSelectedType(SAM_TYPES.WEAR);
                }}
                onMouseOver={(e) => (e.currentTarget.src = activeWear)}
                onMouseOut={(e) =>
                  (e.currentTarget.src =
                    selectedType === SAM_TYPES.WEAR ? activeWear : Wear)
                }
                src={selectedType === SAM_TYPES.WEAR ? activeWear : Wear}
                alt=""
              /> */}
            </li>
            <li className="hover:bg-black pb-1 px-1.5 md:px-4">
              {/* <img
                className={`inline-block py-1 h-10 md:h-11 rounded-full hover:cursor-pointer`}
                onClick={() => { setSelectedType(SAM_TYPES.TIZEN); }}
                onMouseOver={(e) => (e.currentTarget.src = activeTizen)}
                onMouseOut={(e) =>
                  (e.currentTarget.src =
                    selectedType === SAM_TYPES.TIZEN ? activeTizen : Tizen)
                }
                src={selectedType === SAM_TYPES.TIZEN ? activeTizen : Tizen}
                alt=""
              /> */}
            </li>
            {/* <li className="hover:bg-black pb-1 px-1.5 md:px-4">
              <SearchBar 
                setIsSbOpen = { setIsSbOpen }
              />
            </li> */}
          </ul>
          {loading ? (
            <div className="h-[calc(100vh-146px)] md:h-[calc(100vh-150px)] mt-14 animate-pulse flex flex-row justify-center py-0 md:py-4 text-white" />
            ) : !data.length ? ( <NoData />) 
            : (
                <>
                  <div className="h-[calc(100vh-90px)] md:h-[calc(100vh-94px)] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
                    <Banner />
                    <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-5 2xl:grid-cols-8 gap-3 sm:gap-4 xl:grid-cols-6 gap-6 mb-[50px] w-fit m-auto">
                      {data.map((item: any, index: Key) => {
                        return (
                          <CarouselList
                            key={index}
                            onclick={(e: any) => {
                              setProduct(item);
                              setIsOpen(true);
                            }}
                            selectedType={selectedType}
                            data={item}
                            selectedWatchFrame={selectedWatchFrame}
                          />
                        );
                      })}
                    </div>
                    
                    {pagination?.total_page > page ? (
                      <div ref={targetRef}>
                        <LoadMore
                          onClick={() => {
                            setPage(page + 1);
                            loadMore();
                          }}
                          disable={ showProgressBar }
                        />
                      </div>
                    ) : undefined}
                  </div>
              </>
            )}
        </div>
        <CouponPopup
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          product={product}
          selected={selectedType}
          selectedWatchFrame={selectedWatchFrame}
        />
        
        {/* <SearchModal 
          isSbOpen={isSbOpen}
          setIsSbOpen={setIsSbOpen}
        /> */}
      </div>
      {/* <GoogleAds /> */}
      <FloatingMenu 
          type={MENU_TYPE.SAM_WATCH}
          isClick={floatingClick}
          setIsClick={setFloatingClick}
          setselectedWatchFrame={setSelectedWatchFrame}
      />
      <CircularIndicator 
        color={SAM_COLOR.SAM_WATCH}
        show={showProgressBar}
      />
    </div>
  );
};
