import { Key, useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { GET_SAM_SWITCH } from "../../../../service/apiEndPoints";
import { apiCall, METHOD } from "../../../../service/baseApiCall";
import { SAM_COLOR, SAM_TYPES } from "../../utils/constants";
import { Pagination, ThemeName } from "../../utils/enums";
import CarouselList from "../common/CarouselList";
import CouponPopup from "../common/CouponPopup";
import { LoadMore } from "../common/LoadMore";
import { NoData } from "../common/NoData";
import { Navigation } from "../NavigationBar";
import { useLoadState } from "../../../common/globalState";
import { CircularIndicator } from "../common/Indicator";
import { Banner } from "../Banner";

export const FSamTheme = () => {
  const targetRef = useRef<HTMLDivElement>(null);
  const [data, setData] = useState([] as any);
  const [pagination, setPagination] = useState<Pagination>({
    column: "",
    limit: 18,
    offset: 0,
    order: "created_at",
    page: 1,
    query: "",
    sort: "ASC",
    total: 1,
    total_page: 1,
  });
  const [selectedType, setSelectedType] = useState(SAM_TYPES.ALL);
  const [loading, setLoader] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [product, setProduct] = useState();
  const [page, setPage] = useState(1);
  const {showProgressBar, setProgressBar} = useLoadState()

  useEffect(() => {
    setLoader(true);
    setProgressBar(true)
    setPage(1);
    const URL = GET_SAM_SWITCH.replace(":type", "theme")
      .replace(":limit", "40")
      .replace(":page", "1")
      .replace(":os", selectedType === "all" ? "" : selectedType)
      .replace(":name_sku", "");
    apiCall(
      URL,
      {},
      (res: any) => {
        if (res?.success) {
          setData(res?.data);
          setPagination(res?.pagination);
          setLoader(false);
          setProgressBar(false)
        } else {
          if (res?.data?.message === "record not found") {
            setData([]);
            setLoader(false);
            setProgressBar(false)
          }
        }
      },
      (err: any) => {
        console.log(err);
        setLoader(false);
        setProgressBar(false)
        toast.error("something went wrong!");
      },
      METHOD.GET,
      {}
    );
  }, [selectedType]);

  const loadMore = () => {
    setProgressBar(true)
    const URL = GET_SAM_SWITCH.replace(":type", "theme")
      .replace(":limit", "40")
      .replace(":page", (page + 1).toString())
      .replace(":os", selectedType === "all" ? "" : selectedType)
      .replace(":name_sku", "");
    apiCall(
      URL,
      {},
      (res: any) => {
        if (res?.success) {
          const newData = [...data, ...res?.data];
          setData(newData);
          setPagination(res?.pagination);
          setProgressBar(false)
        }
      },
      (err: any) => {
        console.log(err);
        setProgressBar(false)
      },
      METHOD.GET,
      {}
    );
  };
  return (
    <>
      <div className="bg-black w-screen">
        <Navigation name={ThemeName.SAM_THEME} />
        <div>
          <ul className="flex flex-wrap mt-2 text-sm font-normal md:font-medium text-center text-gray-500 dark:text-gray-400 justify-center">
            <li className="hover:bg-black pb-1 items-center px-1.5 md:px-4">
              {/* <button
                className={`inline-block py-1 h-fit md:py-3 px-2 md:px-4 text-white rounded-full ${
                  selectedType === SAM_TYPES.ALL
                    ? "bg-white text-black"
                    : "bg-[#6d6d6d] hover:bg-white hover:text-black"
                }`}
                onClick={() => {
                  setSelectedType(SAM_TYPES.ALL);
                }}
              >
                All
              </button> */}
            </li>
            <li className="hover:bg-black pb-1 px-1.5 md:px-4">
              {/* <img
                className={`inline-block py-1 h-10 md:h-11 hover:cursor-pointer`}
                onClick={() => {
                  setSelectedType(SAM_TYPES.WEAR);
                }}
                onMouseOver={(e) => (e.currentTarget.src = activeWear)}
                onMouseOut={(e) =>
                  (e.currentTarget.src =
                    selectedType === SAM_TYPES.WEAR ? activeWear : Wear)
                }
                src={selectedType === SAM_TYPES.WEAR ? activeWear : Wear}
                alt=""
              /> */}
            </li>
            <li className="hover:bg-black pb-1 px-1.5 md:px-4">
              {/* <img
                className={`inline-block py-1 h-10 md:h-11 rounded-full hover:cursor-pointer`}
                onClick={() => {
                  setSelectedType(SAM_TYPES.TIZEN);
                }}
                onMouseOver={(e) => (e.currentTarget.src = activeTizen)}
                onMouseOut={(e) =>
                  (e.currentTarget.src =
                    selectedType === SAM_TYPES.TIZEN ? activeTizen : Tizen)
                }
                src={selectedType === SAM_TYPES.TIZEN ? activeTizen : Tizen}
                alt=""
              /> */}
            </li>
          </ul>
          {loading ? (
            <div className="h-[calc(100vh-146px)] md:h-[calc(100vh-150px)] mt-14 animate-pulse flex flex-row justify-center py-4">
            </div>
          ) : !data.length ? (
            <NoData />
          ) : (
            <div className="h-[calc(100vh-90px)] md:h-[calc(100vh-94px)] overflow-y-auto">
              <Banner />
              <div className="m-auto grid grid-cols-3 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-3 sm:gap-4 lg:gap-6 2xl:gap-8 mb-10 w-fit">
                {data.map((item: any, index: Key) => {
                  return (
                    <CarouselList
                      key={index}
                      onclick={(e: any) => {
                        setProduct(item);
                        setIsOpen(true);
                      }}
                      selectedType={selectedType}
                      data={item}
                      theme={true}
                    />
                  );
                })}
              </div>
              {pagination?.total_page > page ? (
                <div ref={targetRef}>
                  <LoadMore
                    onClick={() => {
                      setPage(page + 1);
                      loadMore();
                    }}
                    disable={showProgressBar}
                  />
                </div>
              ) : undefined}
            </div>
          )}
        </div>
        <CouponPopup
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          product={product}
          selected={selectedType}
          selectedWatchFrame=""
        />
      </div>
      {/* <GoogleAds /> */}
      <CircularIndicator 
        color={SAM_COLOR.SAM_THEME}
        show={showProgressBar}
      />
    </>
    
  );
};
