import { Dropdown } from "../common/Dropdown";
// import SearchIcon from "../../../../assets/icons/search.svg";
import RefreshIcon from "../../../../assets/icons/refresh.svg";
import { SideBar } from "../Sidebar";
import { useEffect, useState } from "react";
import { apiCall, METHOD } from "../../../../service/baseApiCall";
import { toast } from "react-toastify";
import clsx from "clsx";
import ReLoginModal from "../common/ReloginModal";
import TablePagination from "../common/TablePagination";
// import { refreshToken, validateToken } from "../../../App/utils/util";
import _ from "lodash";
import { RegisterBanner } from "./register";
import { GET_BANNER } from "../../../../service/apiEndPoints";
import { bannerBody, bannerTypeEnum } from "./interface";
import moment from "moment";
import { BannerManage } from "./manage";

export const BannerPage = () => {
    const [data, setData] = useState<bannerBody[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loader, setLoader] = useState(true);
    const [collapsed, setCollapsed] = useState(false);
    const [isReloginOpen, setReloginIsOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [openRegisterModal, setOpenRegisterModal ] = useState<boolean>(false)
    const [openManageModal, setOpenManageModal ] = useState<boolean>(false)
    const [selectedSku, setSelectedSku ] = useState<string>("")
    const [type, setType] = useState<bannerTypeEnum>(bannerTypeEnum.UPDATE)

    useEffect(() => {
        loadData();
    }, [limit, page]);

    const loadData = async () => {
        apiCall(
            GET_BANNER,
            {},
            (res: any) => {
                setData(res.data)
                setLoader(false)
                setTotalCount(res.data.length)
            },
            (err: any) => {
                setData([])
                toast.error(err.message)
                setLoader(false)
                setTotalCount(0)
            },
            METHOD.GET,
            {}
        )
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setPage(0);
    };

    return (
        <div className="flex w-full">
            <SideBar
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                fromCoupon={true}
            />
            <div
                className={clsx(
                "mainClass relative flex flex-col justify-center overflow-auto",
                collapsed
                    ? "ml-12 lg:ml-14 xl:ml-14 w-full"
                    : "ml-12 w-full md:ml-[13em]"
                )}
            >
                <div className="flex w-full flex-row justify-between">
                    <span className="text-3xl font-bold p-4">Banner</span>
                    <button
                        onClick={() => setOpenRegisterModal(true)}
                        className="text-white bg-sky-500 hover:bg-sky-600 font-medium rounded-lg text-sm m-4 mt-5 px-2 py-2.5 w-24"
                    >
                        Register
                    </button>
                </div>

                <div className="flex flex-row justify-between align-middle mt-14 ml-5 mr-3 md:ml-10 md:mr-5">
                    <form
                        className="flex flex-col md:flex-row w-full justify-end"
                        onSubmit={handleSubmit}
                    >
                        <div className="[&>*]:mr-2 [&>*]:hover:cursor-pointer mr-0 mt-8 flex flex-row h-10">
                            {/* <button>
                                <img src={SearchIcon} alt="" />
                            </button> */}
                            <img src={RefreshIcon} onClick={loadData} alt="" />
                        </div>
                    </form>
                </div>
                {/* 경계선 */}
                <div className="divide-y mt-5">
                    <div />
                    <div />
                </div>
                {/*  */}

                <div className="ml-5 mr-3 md:ml-10 md:mr-5 mt-5 flex flex-row justify-between">
                <div className=" flex flex-row">
                    <span className="font-bold">Items:</span>
                    <span className="text-[#697274] ml-2 animate-pulse">
                        {loader ? (
                            <div className="h-2 w-4 bg-slate-600 rounded mt-2" />
                        ) : (
                            totalCount
                        )}
                    </span>
                </div>
                <Dropdown
                    className="-mt-4 -mb-2 mr-2"
                    for="search"
                    onChange={(e: number) => setLimit(e)}
                    items={[
                        { key: "10", value: 10 },
                        { key: "15", value: 15 },
                        { key: "20", value: 20 },
                        { key: "25", value: 25 },
                        { key: "50", value: 50 },
                        { key: "100", value: 100 },
                    ]}
                />
                </div>
                <div className="ml-5 mr-3 md:ml-10 md:mr-7 mt-5 overflow-auto">
                <table className="w-full [&>*]:border">
                    <thead className="">
                    <tr className="bg-[#EFF7FD] [&>*]:p-5 [&>*]:border">
                        <th className="w-[50px]">No</th>
                        <th className="w-[120px]">SKU</th>
                        <th className="w-[20%]">미리보기</th>
                        <th className="w-[10%]">배너 링크</th>
                        <th className="w-[30%]">배너 이미지 주소</th>
                        <th className="w-[10%]">생성일</th>
                        <th className="w-[20%]">관리</th>
                    </tr>
                    </thead>
                    <tbody className="[&>*]:border">
                        {loader ? (
                            <tr className="[&>*]:border-r-[1px] animate-pulse">
                                <td>
                                    <div className="h-2 bg-slate-700 rounded" />
                                </td>
                                <td>
                                    <div className="h-2 bg-slate-700 rounded" />
                                </td>
                                <td>
                                    <div className="h-2 bg-slate-700 rounded" />
                                </td>
                                <td>
                                    <div className="h-2 bg-slate-700 rounded" />
                                </td>
                                <td>
                                    <div className="h-2 bg-slate-700 rounded" />
                                </td>
                                <td>
                                    <div className="h-2 bg-slate-700 rounded" />
                                </td>
                                <td>
                                    <div className="h-2 bg-slate-700 rounded" />
                                </td>
                            </tr>
                        ) : !data.length ? (
                            <tr className="[&>*]:border-r-[1px]">
                            <td colSpan={9} className="font-bold text-gray-700">
                                No Data to Show
                            </td>
                            </tr>
                        ) : (
                            data.map((e: bannerBody, i) => (
                                <tr className="[&>*]:border-r-[1px]">
                                    <td>{(page - 1) * limit + ++i}</td>
                                    <td>
                                        {e.sku}
                                    </td>
                                    <td>
                                        <img src={e?.banner_url} alt="" />
                                    </td>
                                    <td>
                                        <a 
                                            className="text-xl"
                                            href={e?.banner_link} 
                                            target="_blank" 
                                            rel="noreferrer noopener"
                                        >
                                            {e?.banner_link}
                                        </a>
                                    </td>
                                    <td>
                                        <a 
                                            className="text-xl"
                                            href={e?.banner_url} 
                                            target="_blank" 
                                            rel="noreferrer noopener"
                                        >
                                            {e?.banner_url}
                                        </a>
                                    </td>
                                    <td className="text-xl">
                                        {
                                            `${moment(e?.created_at).format("YYYY/MM/DD HH:mm:ss")} KST` 
                                        }
                                    </td>
                                    <td>
                                        {/* FIXME - 수정 기능 추가 시 사용 */}
                                        {/* <button
                                            className={`text-2xl text-white bg-[#0EB4F3] hover:bg-[#58c7f3] 
                                                        font-medium text-center rounded-lg h-fit text-sm 
                                                        m-4 mt-5 px-2 py-2.5 w-24`
                                                      }
                                            onClick={ () => {
                                                setOpenManageModal(true)
                                                setType(bannerTypeEnum.UPDATE)
                                                setSelectedSku(e.sku)
                                            }}
                                        >
                                            수정
                                        </button> */}
                                        <button
                                            className={`text-2xl text-white bg-[#d32f2f] hover:bg-[#dd6868] 
                                                        font-medium text-center rounded-lg h-fit text-sm 
                                                        m-4 mt-5 px-2 py-2.5 w-24`
                                                      }
                                            onClick={ () => {
                                                setOpenManageModal(true)
                                                setType(bannerTypeEnum.DELETE)
                                                setSelectedSku(e.sku)
                                            }}
                                        >
                                            삭제
                                        </button>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
                </div>
                {loader || totalPages <= 1 ? undefined : (
                <TablePagination
                    curPage={page}
                    setCurPage={setPage}
                    lastPage={totalPages}
                />
                )}
            </div>
            <RegisterBanner 
                isOpen={openRegisterModal}
                setOpen={setOpenRegisterModal}
            />
            <ReLoginModal isOpen={isReloginOpen} setIsOpen={setReloginIsOpen} />
            <BannerManage 
                isOpen={openManageModal}
                setOpen={setOpenManageModal}
                sku={selectedSku}
                type={type}
            />
        </div>
    );
};