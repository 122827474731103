import dayjs from "dayjs"
import utc from 'dayjs/plugin/utc'
import { SET_DISCOUNT } from "../../../../service/apiEndPoints";
import { apiCall, METHOD } from "../../../../service/baseApiCall";
import { toast } from "react-toastify";

dayjs.extend(utc)

export const handleDiscountSubmit = (
        calendarStartTimeValue: dayjs.Dayjs | null, 
        calendarStartDateValue: dayjs.Dayjs | null,
        calendarEndTimeValue: dayjs.Dayjs | null,
        calendarEndDateValue: dayjs.Dayjs | null,
        sku: string,
        discount_rate: number,
        type: string
    ) => {
        if (!calendarStartDateValue || !calendarStartTimeValue || !calendarEndDateValue || !calendarEndTimeValue) {
            return;
        }

        const URL = SET_DISCOUNT.replace(":type", type);
        const formData = new FormData();
        
        const startDate = new Date(Date.UTC(
            calendarStartDateValue.year(), 
            calendarStartDateValue.month(),
            calendarStartDateValue.date(), 
            calendarStartTimeValue.hour(), 
            calendarStartTimeValue.minute()
        ));

        const endDate = new Date(Date.UTC(
            calendarEndDateValue.year(), 
            calendarEndDateValue.month(),
            calendarEndDateValue.date(), 
            calendarEndTimeValue.hour(), 
            calendarEndTimeValue.minute()
        ));

        formData.append('start_date', startDate.toISOString());
        formData.append('end_date', endDate.toISOString());
        formData.append('discount_rate', discount_rate.toString());
        formData.append('sku', sku);

        apiCall(
            URL,
            formData,
            (res: any) => {
                toast.success("할인을 설정하였습니다.")
                setTimeout(() => {
                    window.location.reload()
                }, 1000)
                return
            },
            (err: any) => {
                if(err === "unauthorized") {
                    toast.error("인증정보가 만료되었습니다. 다시 로그인해주세요." )
                    return
                }
                toast.error("할인을 설정하는데 실패하였습니다.", err)
            },
            METHOD.PATCH,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
}