import { MENU_TYPE } from "../../utils/constants";
import { Pagination } from "../../utils/enums";
import { Banner } from "../Banner";
// import { GoogleAds } from "../common/Ads";
import CarouselList from "../common/CarouselList";
import { FloatingMenu } from "../common/FloatingMenu";
import { LoadMore } from "../common/LoadMore";
import { NoData } from "../common/NoData";
import { Key, useRef } from "react"

interface FYamWatchBodyData {
    loading: boolean,
    data: any
    page: number
    pagination: Pagination
    selectedType: string
    selectedWatchFrame: string
    floatingClick: boolean
    showProgressBar: boolean
    loadMore: () => void
    setPage: React.Dispatch<React.SetStateAction<number>>
    setFloatingClick: React.Dispatch<React.SetStateAction<boolean>>
    setProduct: React.Dispatch<React.SetStateAction<undefined>>
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    setSelectedWatchFrame: React.Dispatch<React.SetStateAction<string>>
}

export const FYamWatchBody = ( props: FYamWatchBodyData) => {
    const targetRef = useRef<HTMLDivElement>(null);


    return (
        <>
            <ul className="flex flex-wrap mt-2 text-sm font-normal md:font-medium text-center text-gray-500 dark:text-gray-400 justify-center">
                <li className="hover:bg-black pb-1 items-center px-1.5 md:px-4">
                </li>
                <li className="hover:bg-black pb-1 px-1.5 md:px-4">
                </li>
                <li className="hover:bg-black pb-1 px-1.5 md:px-4">
                </li>
            </ul>

            {props.loading ? (
                <div className="h-[calc(100vh-146px)] md:h-[calc(100vh-150px)] mt-14 animate-pulse flex flex-row justify-center py-0 md:py-4 text-white">
                </div>
            ) : !props.data.length ? (
                <NoData />
            ) : (
                <>
                    <div className="h-[calc(100vh-90px)] md:h-[calc(100vh-94px)] overflow-y-auto">
                    <Banner />
                    <div className="grid grid-cols-3 sm:grid-cols-4 md:grid-cols-4 lg:grid-cols-6 2xl:grid-cols-8 gap-3 sm:gap-4 xl:gap-6 mb-[50px]  w-fit m-auto">
                        {props.data.map((item: any, index: Key) => {
                        return (
                            <CarouselList
                            key={index}
                            onclick={(e: any) => {
                                props.setProduct(item);
                                props.setIsOpen(true);
                            }}
                            selectedType={props.selectedType}
                            data={item}
                            selectedWatchFrame={props.selectedWatchFrame}
                            />
                        );
                        })}
                    </div>
                    {/* <GoogleAds /> */}
                    <FloatingMenu 
                        type={MENU_TYPE.YAM_WATCH}
                        isClick={props.floatingClick}
                        setIsClick={props.setFloatingClick}
                        setselectedWatchFrame={props.setSelectedWatchFrame}
                    />
                    {props.pagination?.total_page > props.page ? (
                        <div ref={targetRef}>
                        <LoadMore
                            onClick={() => {
                                props.setPage(props.page + 1);
                                props.loadMore();
                            }}
                            disable={props.showProgressBar}
                        />
                        </div>
                    ) : undefined}
                    </div>
                    
                </>
            )}
        </>
    )
}