interface DiscountProps {
    is_discount: boolean;
    discount_rate: number;
    style?: React.CSSProperties;
}

const Discount = ({ is_discount, discount_rate, style }: DiscountProps) => {
    return (
        is_discount ? (  
            <div 
                className="bg-[#CE3532] cursor-pointer w-full text-white 
                           rotate-[320deg] font-bold flex items-center justify-center 
                           min-[300px]: text-[8px] sm: text-[9px] h-[20px] md: text-[10px] 
                           lg: text-[11px]  xl: text-[12px] 2xl:text-[13px] h-[25px]"
                style={style}
                >
                {discount_rate}% OFF
            </div>
        ) : null 
    )
};

export default Discount;
