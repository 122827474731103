import { Dialog, Transition } from "@headlessui/react"
import { ChangeEvent, DragEvent, Fragment, useState, useEffect } from "react"
import { modalProps } from "./interface"
import { X } from "react-feather";
import { TextField, Button } from "@mui/material";
import { toast } from "react-toastify"
import { apiCall, METHOD } from "../../../../service/baseApiCall";
import { UPDATE_BANNER } from "../../../../service/apiEndPoints";
import { generateUniqueString, refreshToken } from "../../../App/utils/util";

/* FIXME - Modal을 재사용 가능한 컴포넌트로 작성해야하는데 이전 코드를 잘못 짜서 일일히 새로 만드는 중
   누가 나중에 수정해주었으면 함
 */

const BannerBody = (props: any) => {
    const [ isActive, setActive ] = useState<boolean>(false) 
    const [ imagePreivew, setImagePreview ] = useState<string | null>()
    const handleDragStart = () => { setActive(true) }
    const handleDragEnd = () => { setActive(false) }
    const handleDragOver = (e: DragEvent) => { 
        e.preventDefault()
    }

    const uploadBanner = () => {
        let formData = new FormData()
        formData.append("image", props.imageInfo as Blob)
        formData.append("banner_link", props.urlInfo)
        formData.append("sku", generateUniqueString(16))

        const URL = UPDATE_BANNER.replace("/:sku", "")
        apiCall(
            URL,
            formData,
            (res: any) => {
                toast.success(res.message)
                props.closeHandler()
                setTimeout(() => {
                    window.location.reload()
                }, 1000)
            },
            async(err: any) => {
                if( err === "unauthorized") {
                    toast.error("로그인 정보가 만료되었습니다. 다시 로그인 해주세요.")
                    props.closeHandler()
                    return
                }
                toast.error(err.message)
            },
            METHOD.POST,
            {}
        )
    }

    const handleDrop = (e: DragEvent) => {
        e.preventDefault()
        if(e.dataTransfer.files[0].type.split("/")[0] !== "image") {
            toast.error("이미지 파일만 업로드가 가능합니다.")
            setActive(false)
            return
        }
        props.setImageInfo( e.dataTransfer.files[0])
        handlePreview(e.dataTransfer.files[0])
        setActive(false)
    }

    const handleUpload = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let files = (e.target as HTMLInputElement).files
        if(files) {
            props.setImageInfo(files[0])
            handlePreview(files[0])
        }
    }

    const handleUrl = (e: React.ChangeEvent<HTMLInputElement>) => { props.setUrlInfo(e.target.value) }
    const handlePreview = (image: Blob ) => {
        let reader = new FileReader()
        reader.readAsDataURL(image)
        reader.onloadend = () => {
            setImagePreview(reader.result as string)
        }
    }

    return (
        <div className="flex flex-col gap-2 h-full w-full">
            <label className={`${isActive ? 'border-slate-400 bg-gray-200' : ''} 
                                flex justify-center items-center border-gray-200 border-4 overflow-auto
                                border-dotted w-full h-4/6 mt-4 hover:border-slate-400 hover:bg-gray-200 cursor-pointer`
                             }
                onDragEnter={handleDragStart}
                onDragLeave={handleDragEnd}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >
                <img src={imagePreivew as string} 
                    className={`${!!imagePreivew === false ? 'hidden' : 'block'} w-full h-4/6 mt-4`}
                    alt=""
                />
                <input type="file" className="hidden" onChange={handleUpload} accept="image/*" />
                <p className={`${!!imagePreivew === false ? '' : 'hidden'} font-bold`} >이미지 드래그 앤 드롭 또는 클릭</p>
            </label>
            
            <TextField 
                fullWidth
                label="배너 URL" 
                id="url" 
                defaultValue={""} 
                onChange={handleUrl}
            />
            <div className="flex gap-2 justify-end">
                <Button 
                    variant="contained" 
                    color="success"
                    onClick={uploadBanner}
                >
                    등록
                </Button>
                <Button variant="contained" color="error" onClick={props.closeHandler}>
                    취소
                </Button>
            </div>

        </div>
    )
}

export const RegisterBanner = (props: modalProps ) => {
    const [imageInfo, setImageInfo] = useState<FileList>()
    const [urlInfo, setUrlInfo] = useState<string>("")

    const closeHandler = () => { props.setOpen(false) }

    useEffect(() => {
        return () => {
            if(props.isOpen === false) {
                setImageInfo(undefined)
                setUrlInfo("")
            }
        }
    }, [props.isOpen])
    
    return (
        <Transition appear show={props.isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" 
                    onClose={() => props.setOpen(false)}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>
                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child>
                            <Dialog.Panel 
                                className="w-[40%] min-w-[720px] transform overflow-hidden rounded-2xl 
                                           bg-white p-6 text-left align-middle shadow-xl transition-all"
                            >
                                <Dialog.Title
                                    as="h3"
                                    className="text-xl font-medium leading-6 text-gray-900"
                                >
                                    <div style={{ alignItems: "center", justifyContent: "space-between"}} className="flex">
                                        <div>
                                            배너 설정
                                        </div>
                                        <div
                                            style={{ cursor: "pointer"}}
                                            onClick={() => props.setOpen(false)}
                                        >
                                            <X className="text-white sm:h-8 sm:w-8 bg-[#d23434] rounded shadow-md hover:cursor-pointer" />
                                        </div>
                                    </div>
                                </Dialog.Title>
                                <div className="mt-4 h-[300px] border-t border-black">
                                    <BannerBody 
                                        imageInfo={imageInfo}
                                        urlInfo={urlInfo}
                                        setImageInfo={setImageInfo}
                                        setUrlInfo={setUrlInfo}
                                        closeHandler={closeHandler}
                                    />
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}